import React, { useState, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useStaticQuery, graphql } from 'gatsby';
import { Typography, Button, IconButton, Container, useTheme, useMediaQuery } from '@mui/material';
import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import clsx from 'clsx';
import { FooterHeightStateContext } from '@context/footerHeightContext'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'

// Import Swiper styles
import 'swiper/css';

const PREFIX = 'NewsSection';

const classes = {
  backgroundRoot: `${PREFIX}-backgroundRoot`,
  content: `${PREFIX}-content`,
  innerWrapper: `${PREFIX}-innerWrapper`,
  h2: `${PREFIX}-h2`,
  h4: `${PREFIX}-h4`,
  button: `${PREFIX}-button`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  swiper: `${PREFIX}-swiper`,
  slideContent: `${PREFIX}-slideContent`,
  swiperButtonsContainer: `${PREFIX}-swiperButtonsContainer`,
  primaryDark: `${PREFIX}-primaryDark`,
  date: `${PREFIX}-date`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.backgroundRoot}`]: {
    display: 'grid',
    backgroundColor: theme.palette.secondary.light,
  },

  [`& .${classes.content}`]: {
    gridArea: "1/1",
    position: "relative",
    '@media (min-width:414px)': {
      backgroundColor: `rgba(255, 255, 181, 0.52)`,
    }
  },

  [`& .${classes.innerWrapper}`]: {
    padding: theme.spacing(4, 2),
  },

  [`& .${classes.h2}`]: {
    padding: theme.spacing(3, 0),
  },

  [`& .${classes.h4}`]: {
    fontWeight: '600 !important',
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3)
    }
  },

  [`& .${classes.button}`]: {
    fontSize: '16px !important',
    fontWeight: '500 !important',
    textTransform: 'lowercase !important',
    backgroundColor: `${theme.palette.common.white} !important`,
    borderRadius: '7px !important',
    padding: '4px 26px !important',
  },

  [`& .${classes.buttonContainer}`]: {
    margin: theme.spacing(2, 0),
    textAlign: 'right',
  },

  [`& .${classes.swiper}`]: {
    maxWidth: `calc(100vw - ${theme.spacing(8)})`, //hack to fix slider on small screens
    backgroundColor: theme.palette.background.transparent,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3)
    }
  },

  [`& .${classes.slideContent}`]: {
    margin: theme.spacing(4, 2),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4, 10)
    }
  },

  [`& .${classes.swiperButtonsContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  [`& .${classes.primaryDark}`]: {
    color: theme.palette.primary.dark,
    fontSize: 15,
  },

  [`& .${classes.date}`]: {
    fontFamily: 'Prompt',
    display: 'inline-block',
    fontStyle: 'normal !important',
    fontWeight: '300',
    fontSize: 15,
    lineHeight: '24px',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1)
  }
}));

SwiperCore.use([Navigation])

const NewsSection = () => {

  const theme = useTheme()
  const matchesDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const footerHeight = useContext(FooterHeightStateContext)
  const [ activeIndex, setActiveIndex ] = useState(0)
  const { allWpPartnerNews: { newsList }, backgroundLarge, backgroundSmall } = useStaticQuery(graphql`
    query News {
      allWpPartnerNews(limit: 6) {
        newsList: nodes {
          id
          title
          partnerNewsDetails {
            newsContent
            partnerLink
            publishDate
          }
        }
      }
      backgroundLarge: file(relativePath: {eq: "news_background_large.jpg"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
      backgroundSmall: file(relativePath: {eq: "news_background.jpg"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const images = useMemo(() => withArtDirection(getImage(backgroundLarge), [
    {
      media: "(max-width: 414px)",
      image: getImage(backgroundSmall),
    },
  ]), [withArtDirection, getImage, backgroundLarge, backgroundSmall])

  if(!newsList.length) return null;

  return (
    <Root 
      className={classes.backgroundRoot}
      style={{
        marginBottom: -footerHeight, 
      }}
    >
      <GatsbyImage 
        image={images}
        alt=""
        layout="fullWidth"
        placeholder="blurred"
        formats={["auto", "webp", "avif"]}
        // transformOptions={{ fit: 'cover'}}
        style={{
          gridArea: "1/1",
        }}
      />
      <div 
      className={classes.content}
      style={{
        paddingBottom: footerHeight + +theme.spacing(2).slice(0, -2)
      }}>
        <Container maxWidth="md" disableGutters>
          <div className={classes.innerWrapper}>
            <Typography component="h2" variant={ matchesDesktop ? "h2" : "h1"} color="primary" className={classes.h2}>
            News from partners
            </Typography>
            <Swiper
            className={classes.swiper}
            slidesPerView={1}
            navigation={{
              nextEl: '.swiper-button-next-custom',
              prevEl: '.swiper-button-prev-custom',
            }}
            onSlideChange={s => setActiveIndex(s.activeIndex)}
            >
              {
                newsList
                .sort((newsA, newsB) => newsB.partnerNewsDetails?.publishDate - newsA.partnerNewsDetails?.publishDate)
                .map((news, i) => {
                  return (
                    <SwiperSlide key={news.id} className={classes.slide}>
                      <article className={classes.slideContent}>
                        <Typography component='h3' variant='h4' className={clsx(classes.primaryDark, classes.h4)} paragraph>
                          {news.title}
                        </Typography>
                        <Typography
                          component="div"
                          variant="body2"
                          className={classes.primaryDark}
                          paragraph
                          dangerouslySetInnerHTML={{__html: news.partnerNewsDetails.newsContent}}
                          ></Typography>
                        <time dateTime={news.partnerNewsDetails.publishDate} className={classes.date}>
                          { news.partnerNewsDetails.publishDate }
                        </time>
                        <div className={classes.buttonContainer}>
                          <Button
                            component="a"
                            target="_blank"
                            rel="noopener norefferrer"
                            href={news.partnerNewsDetails.partnerLink}
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            aria-label={`More about ${news.title}`}
                            >
                            learn more
                          </Button>
                        </div>
                      </article>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
            <div className={classes.swiperButtonsContainer}>
              <IconButton aria-label="Next news" className="swiper-button-prev-custom" disabled={activeIndex === 0} size="small">
                <NavigateBefore color={activeIndex === 0 ? "disabled" : "primary"} fontSize="large"/>
              </IconButton>
              <IconButton aria-label="Previous news" className="swiper-button-next-custom" disabled={activeIndex > newsList.length - 2} size="small">
                <NavigateNext color={activeIndex > newsList.length - 2 ? "disabled" : "primary"} fontSize="large"/>
              </IconButton>
            </div>
          </div>
        </Container>
      </div>
    </Root>
  );
}

export default NewsSection;